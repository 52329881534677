/* global newrelic */
import { podFulFillmentUtils } from '@thd-olt-component-react/fulfillment';

export const getUniqueHotspots = (hotspots = []) => {
  const uniqueHotspots = new Map();

  hotspots.forEach((hotspot) => {
    if (!uniqueHotspots.has(hotspot.itemId)) {
      uniqueHotspots.set(hotspot.itemId, hotspot);
    }
  });

  return Array.from(uniqueHotspots.values());
};

const deselectHotspot = (container) => {
  const tempContainer = container;
  const circleIconContainer = container?.children[1];
  const shoppableTagIconContainer = container?.children[2];
  tempContainer.className = 'tag';
  shoppableTagIconContainer.className = 'shoppable-tag-icon sui-hidden';
  circleIconContainer.className = 'circle-tag-icon';
};

const deselectPreviousHotspot = (container) => {
  const activeHotspots = [
    ...document.getElementsByClassName('tag tippy-active')
  ];
  const previouslySelected = activeHotspots.find((hotspot) => hotspot !== container);
  if (previouslySelected) {
    deselectHotspot(previouslySelected);
  }
};

const selectHotspot = (container) => {
  const tempContainer = container;
  const circleIconContainer = container?.children[1];
  const shoppableTagIconContainer = container?.children[2];
  tempContainer.className = 'tag tippy-active';
  shoppableTagIconContainer.className = 'shoppable-tag-icon';
  circleIconContainer.className = 'circle-tag-icon sui-hidden';
};

export const toggleActiveClass = (event) => {
  const container = event?.currentTarget;
  const notSelected = container?.getAttribute('aria-expanded') === 'false';

  if (notSelected) {
    deselectPreviousHotspot(container);
    selectHotspot(container);
  } else {
    const timeToWait = 300;
    // Allow time for the tooltip to close so that deselection of hotspot is synchronized
    setTimeout(() => {
      deselectHotspot(container);
    }, timeToWait);
  }
};

export const imageStyle = (hsXPosition, hsYPosition) => {
  return {
    cursor: 'pointer',
    left: `${hsXPosition * 100}%`,
    top: `${hsYPosition * 100}%`
  };
};

export const triggerAnalytics = (name, value) => {
  LIFE_CYCLE_EVENT_BUS.trigger(name, value);
};

export const getProductInfo = (container, productPrice) => {
  const itemId = container?.itemId;
  const brandName = container?.identifiers?.brandName;
  const productLabel = container?.identifiers?.productLabel;
  const canonicalUrl = container?.identifiers?.canonicalUrl;
  const averageRating = container?.reviews?.ratingsReviews?.averageRating || 0;
  const totalReviews = container?.reviews?.ratingsReviews?.totalReviews || 0;
  const images = container?.media?.images;
  const [productImage] = images || [];

  return {
    itemId,
    averageRating,
    brandName,
    imageUrl: productImage?.url || '',
    productLabel,
    productUrl: canonicalUrl,
    totalReviews,
    price: productPrice ? `${productPrice}`.split('.') : ''
  };
};
export const isProductOutOfStockOnline = (product = {}) => {
  const isBODFS = podFulFillmentUtils.isBODFS(product);
  const isBOPIS = podFulFillmentUtils.isBOPIS(product);
  const isOutOfStock = podFulFillmentUtils.isOutOfStockOnline(product);
  const isOOS = isOutOfStock && !(isBOPIS || isBODFS);
  return isOOS;
};

export const getProductAvailability = (product) => {
  let unfulfillable = false;
  const productLoaded = product && Object.keys(product)?.length !== 0;
  if (productLoaded) {
    const isBODFS = podFulFillmentUtils.isBODFS(product);
    const isBOPIS = podFulFillmentUtils.isBOPIS(product);
    const isSTH = podFulFillmentUtils.isSTH(product) && !podFulFillmentUtils.getExcludedStateSth(product);
    const isBOSS = podFulFillmentUtils.isBOSS(product);
    const isOOS = isProductOutOfStockOnline(product);
    const isFulfillable = podFulFillmentUtils.isFulfillable(product);
    const noFulfillment = !isBODFS && !isSTH && !isBOPIS && !isBOSS;
    const isCurrentlyUnavailable = podFulFillmentUtils.isCurrentlyUnavailable(product);
    const isDiscontinued = podFulFillmentUtils.isDiscontinued(product);

    if (!isFulfillable
      || isOOS
      || noFulfillment
      || isCurrentlyUnavailable
      || isDiscontinued
    ) {
      unfulfillable = true;
    }
  }

  return {
    unfulfillable
  };
};

export const removeActiveClass = (event, hotspotRef, onClick) => {
  const targetId = event?.currentTarget?.id;
  const parentId = event?.currentTarget?.parentNode?.id;
  const eventClassName = event?.target?.className;
  const parentContainer = document.querySelector('.thd-react-tooltip__content ');
  const tooltipContainer = parentContainer?.contains(event.target);
  const clickedOutsideTag = targetId !== 'hotspot-container' && parentId !== 'hotspot-container'
    && !tooltipContainer;
  const active = hotspotRef && hotspotRef.current && hotspotRef.current.className === 'tag tippy-active';
  if (clickedOutsideTag && active) {
    // if the user clicks outside of the tag, we want to close the tooltip
    if ((typeof eventClassName === 'string' && eventClassName !== 'tag tippy-active')
      && eventClassName.indexOf('details') === -1) {
      let hotspotRefTemp = hotspotRef.current;
      hotspotRefTemp = null;
      if (onClick) onClick(event, hotspotRefTemp);
    }
    const container = hotspotRef.current;
    const timeToWait = 150;
    // Allow time for the tooltip to close so that deselection of hotspot is synchronized
    setTimeout(() => {
      deselectHotspot(container);
    }, timeToWait);
  }
};

export const getFilteredProduct = (products, imageURL) => {
  const inStockProducts = (products || []).filter((product) => {
    return !getProductAvailability(product).unfulfillable;
  });
  if (!inStockProducts.length) {
    // Trigger a newrelic event when all the products are unfulfillable.
    if (typeof window !== 'undefined' && typeof window.newrelic !== 'undefined' && window.newrelic.addPageAction) {
      const omsIDs = products.map((product) => product.itemId).join();
      window.newrelic.addPageAction('PIPOOSHotspots', {
        omsIDs,
        imageURL
      });
    }
    return [products[0]];
  }
  return inStockProducts;
};
