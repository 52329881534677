import {
  params,
  arrayOf as arrayOfType,
  bool as boolType,
  string as stringType,
  shape as shapeType,
  extend,
} from '@thd-nucleus/data-sources';

export const detailsDataModel = extend({
  product: params({
    itemId: stringType()
      .isRequired()
  })
    .shape({
      identifiers: shapeType({
        brandName: stringType(),
        canonicalUrl: stringType(),
        productLabel: stringType()
      }),
      info: shapeType({
        hasVisuallySimilar: boolType(),
      }),
      reviews: shapeType({
        ratingsReviews: shapeType({
          averageRating: stringType(),
          totalReviews: stringType()
        })
      }),
      media: shapeType({
        images: arrayOfType(shapeType({
          url: stringType()
        }))
      }),
    })
  });