import React, { useContext } from 'react';
import {
  arrayOf,
  string,
  shape
} from 'prop-types';
import {
  extend,
  QueryProvider
} from '@thd-nucleus/data-sources';
import { useStore } from '@thd-nucleus/experience-context';
import { Price } from '@thd-olt-component-react/price';
import { Carousel } from '@thd-olt-component-react/core-ui';
import { lifeStyleProductsDataModel } from './LifeStyleDataModel';
import { detailsDataModel } from './DetailsDataModel';
import { DetailsCard } from './DetailsCard';
import './styles/details.scss';
import './styles/overrides.scss';

const LifeStyleDetails = ({ imageUrl: lifeStyleImage, products }) => {

  const store = useStore();
  const { storeId, zipCode } = store;

  // We do not need to do an early return here for empty products, because
  // we do not call this subcomponent, LifeStyleDetails, for empty products.

  const carouselSkus = products.length > 5 ? products.slice(0, 5) : products;
  const anchorClass = carouselSkus?.length > 1 ? 'details__carousel-anchor' : 'details';
  return (
    <QueryProvider
      dataSource="searchNav"
      defaultVariables={{ storeId, zipCode, skipKPF: true, skipSubscribeAndSave: true }}
      cacheKey="details"
    >
      <div className="details__container">
        <Carousel
          breakpoints={{
            sm: {
              slidesPerView: 1,
              slidesPerGroup: 1
            },
            md: {
              slidesPerView: 1,
              slidesPerGroup: 1
            },
            lg: {
              slidesPerView: 1,
              slidesPerGroup: 1
            },
            xl: {
              slidesPerView: 1,
              slidesPerGroup: 1
            }
          }}
          pagination
          peek={false}
          navigation
          slidesPerGroup={1}
          slidesPerView={1}
        >
          {(carouselSkus || []).map((product, index) => {
            return (
              <DetailsCard
                product={product}
                anchorClass={anchorClass}
                index={index}
                storeId={storeId}
                key={index}
              />
            );
          })}
        </Carousel>
      </div>
    </QueryProvider>
  );
};

LifeStyleDetails.dataModel = extend({}, lifeStyleProductsDataModel, detailsDataModel, Price);

LifeStyleDetails.propTypes = {
  imageUrl: string,
  products: arrayOf(shape({
    itemId: string
  })).isRequired
};

LifeStyleDetails.defaultProps = {
  imageUrl: ''
};

export { LifeStyleDetails };
