import React, { useContext } from 'react';
import { Row, Col, Image, Rating } from '@thd-olt-component-react/core-ui';
import { Price } from '@thd-olt-component-react/price';
import { ExperienceContext } from '@thd-nucleus/experience-context';
import { number, string, shape } from 'prop-types';
import {
  arrayOf as arrayOfType,
  extend,
  params,
  shape as shapeType,
  string as stringType,
  bool as boolType,
} from '@thd-nucleus/data-sources';
import { detailsDataModel } from './DetailsDataModel';
import { triggerAnalytics, getProductInfo } from '../../helpers';

const DetailsCard = ({ product, anchorClass, index, storeId }) => {

  const {
    itemId,
    averageRating,
    brandName,
    imageUrl,
    productLabel,
    productUrl,
    totalReviews,
  } = getProductInfo(product);

  const { channel } = useContext(ExperienceContext);

  const isMobile = channel === 'mobile';

  const onProductLinkClick = () => {
    triggerAnalytics('product-hotspots.product-link-click', { sku: itemId });
  };

  return (
    <a
      className={anchorClass}
      href={productUrl}
      onClick={onProductLinkClick}
      rel="noreferrer"
      target={isMobile ? '_self' : '_blank'}
      data-testid="tooltip-product"
      key={index}
    >
      <Row className="details__main-row">
        <Col className="details__left-column" fallback="6" nopadding>
          <Image
            alt="Product"
            className="details__product-image"
            src={imageUrl?.replace(/<SIZE>/g, '300')}
            width="100"
            height="50"
          />
        </Col>
        <Col className="details__right-column" fallback="6">
          <Row>
            <span className="details__product-label">
              {productLabel}
            </span>
            {brandName && (
              <span className="details__brand">
                by&nbsp;
                <span className="details__brand--bold">
                  {brandName}
                </span>
              </span>
            )}
          </Row>
          {totalReviews > 0 && (
            <Row className="details__rating">
              <Rating className="details__rating-stars" value={Number(averageRating)} />
              <span>({totalReviews})</span>
            </Row>
          )}
          <Row className="details__price--disable">
            <Price
              itemId={itemId}
              product={product}
              storeId={storeId}
              large={false}
            />
          </Row>
        </Col>
      </Row>
    </a>
  );
};

DetailsCard.dataModel = extend({ detailsDataModel }, Price);

DetailsCard.propTypes = {
  product: shape({
    itemId: string
  }).isRequired,
  anchorClass: string,
  index: number,
  storeId: string.isRequired
};

DetailsCard.defaultProps = {
  anchorClass: '',
  index: 0
};

export { DetailsCard };