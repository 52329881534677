import {
  arrayOf,
  extend,
  params,
  shape,
  string,
  number,
  bool,
} from '@thd-nucleus/data-sources';

export const productDetails = {
  product: shape({
    itemId: string(),
    dataSources: string(),
    availabilityType: shape({
      discontinued: bool(),
      type: string()
    }),
    pricing: params({ storeId: string() }).shape({
      alternatePriceDisplay: bool(),
      alternate: shape({
        bulk: shape({
          pricePerUnit: number({ float: true }),
          thresholdQuantity: number(),
          value: number({ float: true })
        }),
        unit: {
          caseUnitOfMeasure: string(),
          unitsOriginalPrice: number({ float: true }),
          unitsPerCase: number({ float: true }),
          value: number({ float: true }),
        },
      }),
      original: number({ float: true }),
      mapAboveOriginalPrice: bool(),
      message: string(),
      preferredPriceFlag: bool(),
      promotion: shape({
        type: string(),
        dates: shape({
          start: string(),
          end: string()
        }),
        description: shape({
          shortDesc: string(),
          longDesc: string()
        }),
        dollarOff: number({ float: true }),
        percentageOff: number({ float: true }),
        savingsCenter: string(),
        savingsCenterPromos: string(),
        specialBuySavings: string(),
        specialBuyDollarOff: number({ float: true }),
        specialBuyPercentageOff: number({ float: true })
      }),
      specialBuy: number({ float: true }),
      unitOfMeasure: string(),
      value: number({ float: true }),
    }),
    fulfillment: shape({
      fulfillmentOptions: arrayOf(shape({
        type: string(),
        fulfillable: bool(),
        services: arrayOf(shape({
          type: string(),
          locations: arrayOf(shape({
            inventory: shape({
              isOutOfStock: bool(),
              isInStock: bool(),
              isUnavailable: bool(),
              quantity: number(),
            }),
            isAnchor: bool(),
            type: string(),
          }))
        }))
      }))
    }),
    identifiers: shape({
      brandName: string(),
      canonicalUrl: string(),
      productLabel: string(),
      itemId: string(),
    }),
    info: shape({
      hidePrice: bool(),
      ecoRebate: bool(),
      quantityLimit: number(),
      sskMin: string(),
      sskMax: string(),
      unitOfMeasureCoverage: string(),
      wasMaxPriceRange: number({ float: true }),
      wasMinPriceRange: number({ float: true })
    }),
    media: shape({
      images: arrayOf(shape({
        url: string(),
        type: string(),
        subType: string(),
        sizes: arrayOf(string())
      }))
    }),
    reviews: shape({
      ratingsReviews: shape({
        averageRating: string(),
        totalReviews: string(),
      })
    })
  })
};

export const productModel = extend({},
  productDetails.product
);

export const lifeStyleProductsDataModel = {
  searchModel: params({
    itemIds: arrayOf(string()),
    storeId: string()
  }).shape({
    id: string(),
    products: arrayOf(productModel),

  })
};