/* eslint-disable react/no-unknown-property */
import React, { useEffect, useRef } from 'react';
import { string } from 'prop-types';
import './avataar-iframe.style.scss';

export const AvataarIFrame = ({ avataarExperienceLink }) => {
  const avataarRef = useRef(null);

  useEffect(() => {
    if (typeof window !== 'undefined' && window && window.XRIFrame && avataarRef?.current?.id) {
      window.XRIFrame.registerXRIFrame('avtrIframe');
    }
  }, [avataarRef]);

  return (
    <iframe
      ref={avataarRef}
      id="avtrIframe"
      title="Avataar Iframe"
      src={avataarExperienceLink}
      margintop="0"
      frameBorder="10"
      className="avatar-iframe"
      allow="camera;microphone;gyroscope;accelerometer;"
    />
  );

};

AvataarIFrame.propTypes = {
  avataarExperienceLink: string
};

AvataarIFrame.defaultProps = {
  avataarExperienceLink: ''
};

export default AvataarIFrame;