import React, { useEffect, useRef, useState } from 'react';
import { arrayOf, shape, string } from 'prop-types';
import { extend } from '@thd-nucleus/data-sources';
import { Image } from '@thd-olt-component-react/core-ui';
import { LifeStyleHotspot } from './subcomponents/LifeStyleHotspot';
import { Toggle } from './subcomponents/Toggle';
import { MAX_DISPLAYED_HOTSPOTS_COUNT } from '../constants';
import './product-hotspots.scss';

const MediaHotspots = ({ hotspots, imageUrl }) => {
  const [shouldHideHotspots, setShouldHideHotspots] = useState(false);
  const openHotspotRef = useRef();

  const onToggle = (hidden) => {
    setShouldHideHotspots(!hidden);
  };

  useEffect(() => {
    if (LIFE_CYCLE_EVENT_BUS) {
      LIFE_CYCLE_EVENT_BUS.lifeCycle.trigger('product-hotspots.ready');
    }
  }, []);

  const hideOthers = (event, tooltipRef) => {
    const eventTargetName = event?.target?.nodeName;
    // if the hotspot is opened, and we click on it via TAB -> ENTER, then simulate the tag click
    if (event && openHotspotRef && openHotspotRef.current && eventTargetName === 'BUTTON') {
      openHotspotRef.current.click();
    }

    openHotspotRef.current = openHotspotRef.current ? null : tooltipRef;
  };

  return (
    <div className="product-hotspots" data-component="MediaHotspots">
      <Image
        alt="background"
        className="mainImage stretchy"
        src={imageUrl}
        height="1"
        width="1"
      />
      {hotspots.length > 0 && <Toggle onToggle={onToggle} />}
      {hotspots.length > 0 && !shouldHideHotspots
        && hotspots.slice(0, MAX_DISPLAYED_HOTSPOTS_COUNT).map((hotspot, index) => {
          const { coordinate, omsIDs } = hotspot;

          return (
            <LifeStyleHotspot
              key={index}
              hsXPosition={coordinate.xCoordinate}
              hsYPosition={coordinate.yCoordinate}
              onClick={hideOthers}
              hotspotKey={index + 1}
              omsIDs={omsIDs}
              imageUrl={imageUrl}
            />
          );
        })}
    </div>
  );
};

MediaHotspots.displayName = 'MediaHotspots';

MediaHotspots.dataModel = extend(LifeStyleHotspot);

MediaHotspots.propTypes = {
  hotspots: arrayOf(shape({
    coordinate: shape({
      xCoordinate: string,
      yCoordinate: string
    }),
    omsIDs: arrayOf(string)
  })).isRequired,
  imageUrl: string.isRequired
};

export { MediaHotspots };
