import React, { useEffect, useRef, useState } from 'react';
import { func, number, arrayOf, string } from 'prop-types';
import { extend, useDataModel } from '@thd-nucleus/data-sources';
import { useStoreId } from '@thd-nucleus/experience-context';
import { Tooltip } from '@thd-olt-component-react/tooltip';
import { Col, Row, Placeholder, Image } from '@thd-olt-component-react/core-ui';
import { SpecialSavings, RadioButtonChecked } from '@one-thd/sui-icons';
import { LifeStyleDetails } from './LifeStyleDetails';
import { DetailsCard } from './DetailsCard';
import {
  triggerAnalytics,
  imageStyle,
  removeActiveClass,
  toggleActiveClass,
  getFilteredProduct
} from '../../helpers';
import './styles/hotspot.scss';

const LifeStyleHotspot = ({
  hsXPosition, hsYPosition, onClick, hotspotKey, omsIDs, imageUrl
}) => {
  const hotspotRef = useRef();
  const [hotspotClicked, setClickHotspot] = useState(false);

  const hotspotClick = (event) => {
    setClickHotspot(true);
    onClick(event, hotspotRef.current);
    toggleActiveClass(event, '');
    const container = event?.currentTarget;
    const notSelected = container?.getAttribute('aria-expanded') === 'false';
    if (notSelected) {
      triggerAnalytics('product-hotspots.click');
    }
  };

  const removeActiveHotspotClass = (event) => {
    removeActiveClass(event, hotspotRef, onClick);
  };

  useEffect(() => {
    document.body.addEventListener('click', removeActiveHotspotClass);
    return () => {
      document.body.removeEventListener('click', removeActiveHotspotClass);
    };
  }, []);

  const storeId = useStoreId();
  const { data, loading, error } = useDataModel('searchModel', {
    variables: {
      itemIds: omsIDs,
      storeId
    }
  });

  if (error) {
    // We do not need to check for !data here, because we would show the
    // UI for "This item is currently unavailable" in that case.
    return null;
  }

  const { searchModel } = data || {};
  const { products } = searchModel || [];
  if (!data && loading) {
    return (
      <div id={`tooltip-wrapper-div-${hotspotKey}`}>
        <Tooltip
          boundary="viewport"
          channel="desktop"
          content={hotspotClicked && (
            <Row className="details__main-row details__main-row--loading show-loading-animation">
              <Col className="details__left-column" fallback="6" nopadding>
                <Placeholder
                  className="details__loader--left"
                  type="rect"
                  showLoadingAnimation
                />
              </Col>
              <Col className="details__right-column" fallback="6">
                <div className="details__loader--right">
                  { new Array(6).fill('').map((value, i) => (
                    <Placeholder
                      type="text"
                      className="placeholder-text-row"
                      showLoadingAnimation
                      key={i}
                    />
                  ))}
                </div>
              </Col>
            </Row>
          )}
          closeButton
          showClose={false}
          interactive
          appendTo={() => document.querySelector(`#tooltip-wrapper-div-${hotspotKey}`)}
        >
          <button
            tabIndex="0"
            type="button"
            className="tag"
            data-testid="hotspot-container"
            id="hotspot-container"
            onClick={hotspotClick}
            ref={hotspotRef}
            style={imageStyle(hsXPosition, hsYPosition)}
            aria-label="Shop this Product"
          >
            <div className="white-background" />
            <div tabIndex="-1" className="circle-tag-icon" data-testid="circle-tag-hotspot">
              <RadioButtonChecked />
            </div>
            <div tabIndex="-1" className="shoppable-tag-icon sui-hidden" data-testid="hotspot">
              <SpecialSavings />
            </div>
          </button>
        </Tooltip>
      </div>
    );
  }

  if (!data || !products?.length) {
    return (
      <div id={`tooltip-wrapper-div-${hotspotKey}`}>
        <Tooltip
          boundary="viewport"
          channel="desktop"
          content={hotspotClicked && (
            <div className="tooltip_no-data" data-testid="no-data"> This item is currently unavailable </div>
          )}
          closeButton
          showClose={false}
          interactive
          appendTo={() => document.querySelector(`#tooltip-wrapper-div-${hotspotKey}`)}
        >
          <button
            tabIndex="0"
            type="button"
            className="tag"
            data-testid="hotspot-container"
            id="hotspot-container"
            onClick={hotspotClick}
            ref={hotspotRef}
            style={imageStyle(hsXPosition, hsYPosition)}
            aria-label="Shop this Product"
          >
            <div className="white-background" />
            <div tabIndex="-1" className="circle-tag-icon" data-testid="circle-tag-hotspot">
              <RadioButtonChecked />
            </div>
            <div tabIndex="-1" className="shoppable-tag-icon sui-hidden" data-testid="hotspot">
              <SpecialSavings />
            </div>
          </button>
        </Tooltip>
      </div>
    );
  }

  const filteredProducts = getFilteredProduct(products, imageUrl);

  const carouselClass = filteredProducts.length > 1 ? 'thd-react-tooltip__content-carousel' : '';
  const isCarousel = filteredProducts.length > 1;

  return (
    <div id={`tooltip-wrapper-div-${hotspotKey}`}>
      <Tooltip
        className={carouselClass}
        boundary="viewport"
        channel="desktop"
        content={hotspotClicked && (isCarousel
          ? (
            <LifeStyleDetails
              omsIDs={omsIDs}
              imageUrl={imageUrl}
              products={filteredProducts}
            />
          ) : (
            <DetailsCard
              product={filteredProducts[0] || {}}
              storeId={storeId}
              index={0}
              anchorClass="details"
            />
          )
        )}
        closeButton
        showClose={false}
        interactive
        appendTo={() => document.querySelector(`#tooltip-wrapper-div-${hotspotKey}`)}
      >
        <button
          tabIndex="0"
          type="button"
          className="tag"
          data-testid="hotspot-container"
          id="hotspot-container"
          onClick={hotspotClick}
          ref={hotspotRef}
          style={imageStyle(hsXPosition, hsYPosition)}
          aria-label="Shop this Product"
        >
          <div className="white-background" />
          <div tabIndex="-1" className="circle-tag-icon" data-testid="circle-tag-hotspot">
            <RadioButtonChecked />
          </div>
          <div tabIndex="-1" className="shoppable-tag-icon sui-hidden" data-testid="hotspot">
            <SpecialSavings />
          </div>
        </button>
      </Tooltip>
    </div>
  );
};

LifeStyleHotspot.displayName = 'LifeStyleHotspot';

LifeStyleHotspot.propTypes = {
  hotspotKey: number,
  hsXPosition: string.isRequired,
  hsYPosition: string.isRequired,
  onClick: func.isRequired,
  imageUrl: string,
  omsIDs: arrayOf(string)
};

LifeStyleHotspot.defaultProps = {
  hotspotKey: 1,
  omsIDs: [],
  imageUrl: ''
};

LifeStyleHotspot.dataModel = extend(LifeStyleDetails);

export { LifeStyleHotspot };
