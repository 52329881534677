export const AVATAAR_EXPERIENCE_LINK = 'https://orion.avataar.me/engine/AVTR_TNT_EyWTHeup/AVTR_EXP_4e666717/index.html';
export const AVATAAR_ENV = 'prepod';
export const AVATAAR_SKUS = [
  { itemId: '203008658', productId: 'AVTR-WRK-aEvHyeFP' },
  { itemId: '206806288', productId: 'AVTR-WRK-miFSD2US' },
  { itemId: '206806292', productId: 'AVTR-WRK-bzsHVdMt' },
  { itemId: '206806293', productId: 'AVTR-WRK-tzXxkpuw' },
  { itemId: '303516040', productId: 'AVTR-WRK-RdSczdBL' },
  { itemId: '303566482', productId: 'AVTR-WRK-3ZZEVNxz' },
  { itemId: '304580723', productId: 'AVTR-WRK-55CQiy6H' },
  { itemId: '304602853', productId: 'AVTR-WRK-YCbhiypH' },
  { itemId: '304602858', productId: 'AVTR-WRK-i7rykabW' },
  { itemId: '305901526', productId: 'AVTR-WRK-KHzKbuGp' },
  { itemId: '305993886', productId: 'AVTR-WRK-GY8aWeHR' },
  { itemId: '307337023', productId: 'AVTR-WRK-KeXtnk5a' },
  { itemId: '307337039', productId: 'AVTR-WRK-gV9UBTu3' },
  { itemId: '308631043', productId: 'AVTR-WRK-G2hJtXHZ' },
  { itemId: '308736997', productId: 'AVTR-WRK-qEZUeMyx' },
  { itemId: '309122286', productId: 'AVTR-WRK-rZ4vzx8K' },
  { itemId: '309568118', productId: 'AVTR-WRK-JcBUyE5C' },
  { itemId: '310031821', productId: 'AVTR-WRK-CKiayjAR' },
  { itemId: '311041653', productId: 'AVTR-WRK-YmEqLHKr' },
  { itemId: '312106940', productId: 'AVTR-WRK-QfeRR2zF' },
  { itemId: '312275136', productId: 'AVTR-WRK-38gDK5Cm' },
  { itemId: '312284218', productId: 'AVTR-WRK-Ak4inctV' },
  { itemId: '312285981', productId: 'AVTR-WRK-nNefrcEs' },
  { itemId: '312439629', productId: 'AVTR-WRK-xjBAi87M' },
  { itemId: '312439700', productId: 'AVTR-WRK-fykvj85i' },
  { itemId: '312440061', productId: 'AVTR-WRK-LUQprmaV' },
  { itemId: '312440202', productId: 'AVTR-WRK-qj6fWird' },
  { itemId: '312440282', productId: 'AVTR-WRK-uFpAmwUW' },
  { itemId: '312440605', productId: 'AVTR-WRK-mMvD7UtD' },
  { itemId: '312441660', productId: 'AVTR-WRK-E3Ykn63i' },
  { itemId: '312447366', productId: 'AVTR-WRK-HSfwYJ9W' },
  { itemId: '312447400', productId: 'AVTR-WRK-KXqpgusP' },
  { itemId: '312563953', productId: 'AVTR-WRK-AXuHAAvG' },
  { itemId: '312899843', productId: 'AVTR-WRK-VtHKDD64' },
  { itemId: '312903023', productId: 'AVTR-WRK-CYRXWfGJ' },
  { itemId: '313001380', productId: 'AVTR-WRK-PDQk255v' },
  { itemId: '313364546', productId: 'AVTR-WRK-n3YFWgWw' },
  { itemId: '313803342', productId: 'AVTR-WRK-qHCWGnRJ' },
  { itemId: '314024386', productId: 'AVTR-WRK-jfiWu8LE' },
  { itemId: '314103811', productId: 'AVTR-WRK-9arjwAqd' },
  { itemId: '312285857', productId: 'AVTR-WRK-dKWtheWc' },
  { itemId: '312286019', productId: 'AVTR-WRK-BabZiuhD' },
  { itemId: '314755122', productId: 'AVTR-WRK-HW9kPddH' },
  { itemId: '316744779', productId: 'AVTR-WRK-b2xTWtXL' },
  { itemId: '318304560', productId: 'AVTR-WRK-2m5gSc2G' },
  { itemId: '319511547', productId: 'AVTR-WRK-3LCpsWLw' },
  { itemId: '321774731', productId: 'AVTR-WRK-7vbwTNsA' },
  { itemId: '321887244', productId: 'AVTR-WRK-NXce63ms' },
  { itemId: '313364545', productId: 'AVTR-WRK-b6x7JJS7' },
  { itemId: '313846237', productId: 'AVTR-WRK-r8b2cXYK' },
  { itemId: '318268649', productId: 'AVTR-WRK-Z6ExgbbL' },
  { itemId: '320326878', productId: 'AVTR-WRK-3h9PawEB' },
  { itemId: '203008386', productId: 'AVTR-WRK-mDEjEAMT' },
  { itemId: '307337012', productId: 'AVTR-WRK-eDH7LqCb' },
  { itemId: '308631035', productId: 'AVTR-WRK-ypiTLQ3B' },
  { itemId: '310031805', productId: 'AVTR-WRK-3vVvUr8L' },
  { itemId: '312440135', productId: 'AVTR-WRK-bncDsH7Q' },
  { itemId: '312930135', productId: 'AVTR-WRK-wSEYT9iG' },
  { itemId: '313550124', productId: 'AVTR-WRK-Wt4qKK4j' },
  { itemId: '313703135', productId: 'AVTR-WRK-sD4yVwBU' },
  { itemId: '314103818', productId: 'AVTR-WRK-S3MY4W2x' },
  { itemId: '314145556', productId: 'AVTR-WRK-9VxRVyve' },
  { itemId: '314312330', productId: 'AVTR-WRK-WqguMMzP' },
  { itemId: '314611555', productId: 'AVTR-WRK-dhevC4wA' },
  { itemId: '314611558', productId: 'AVTR-WRK-C7G9rrwH' },
  { itemId: '314744262', productId: 'AVTR-WRK-DscX8yZj' },
  { itemId: '315396312', productId: 'AVTR-WRK-NKUBYBcd' },
  { itemId: '315750223', productId: 'AVTR-WRK-SQxGE69n' },
  { itemId: '315868404', productId: 'AVTR-WRK-UfV23dfD' },
  { itemId: '315949991', productId: 'AVTR-WRK-t94ckXj8' },
  { itemId: '315972136', productId: 'AVTR-WRK-7WpzhJPu' },
  { itemId: '317013047', productId: 'AVTR-WRK-83bwrSEi' },
  { itemId: '317436989', productId: 'AVTR-WRK-ptH39RUr' },
  { itemId: '317445728', productId: 'AVTR-WRK-b5MDctAT' },
  { itemId: '317821963', productId: 'AVTR-WRK-qFKuGvsR' },
  { itemId: '317843042', productId: 'AVTR-WRK-j6MLLquu' },
  { itemId: '318055396', productId: 'AVTR-WRK-wEmGnRwU' },
  { itemId: '318100083', productId: 'AVTR-WRK-txqa5cXd' },
  { itemId: '318304556', productId: 'AVTR-WRK-eqwUS6gX' },
  { itemId: '318304557', productId: 'AVTR-WRK-kRTyYKHa' },
  { itemId: '319615947', productId: 'AVTR-WRK-tH2ZUtTx' },
  { itemId: '320119258', productId: 'AVTR-WRK-3s7Vw3UT' },
  { itemId: '320326726', productId: 'AVTR-WRK-2YAH28r8' },
  { itemId: '320326746', productId: 'AVTR-WRK-yr5p6U3K' },
  { itemId: '320484893', productId: 'AVTR-WRK-RXLuUdFn' },
  { itemId: '320690384', productId: 'AVTR-WRK-L5ESArFr' },
  { itemId: '321253238', productId: 'AVTR-WRK-Rbx9JPyN' },
  { itemId: '321898042', productId: 'AVTR-WRK-tx9kDTag' },
  { itemId: '206097910', productId: 'AVTR-WRK-3CA5n84L' },
  { itemId: '206355460', productId: 'AVTR-WRK-qxYMhJex' },
  { itemId: '304602851', productId: 'AVTR-WRK-3E4Hdbf3' },
  { itemId: '304602855', productId: 'AVTR-WRK-SGd4QSrC' },
  { itemId: '305976840', productId: 'AVTR-WRK-GH4kkDrd' },
  { itemId: '305990389', productId: 'AVTR-WRK-EgnBbwpJ' },
  { itemId: '307864717', productId: 'AVTR-WRK-yXWVAVne' },
  { itemId: '312447378', productId: 'AVTR-WRK-ZvP8JLUt' },
  { itemId: '312751609', productId: 'AVTR-WRK-3dyxmTsW' },
  { itemId: '312758394', productId: 'AVTR-WRK-mYyUjQgH' },
  { itemId: '313703138', productId: 'AVTR-WRK-TBXpX2bt' },
  { itemId: '314611556', productId: 'AVTR-WRK-KzaiRktX' },
  { itemId: '314729408', productId: 'AVTR-WRK-pGMA5nad' },
  { itemId: '315100548', productId: 'AVTR-WRK-aGdBEqw4' },
  { itemId: '315123999', productId: 'AVTR-WRK-PZL8gHHy' },
  { itemId: '315125500', productId: 'AVTR-WRK-XZ9trBUt' },
  { itemId: '315125680', productId: 'AVTR-WRK-bLQsDLCK' },
  { itemId: '315396317', productId: 'AVTR-WRK-4xAbf4g4' },
  { itemId: '315416018', productId: 'AVTR-WRK-qKtWpraQ' },
  { itemId: '315868024', productId: 'AVTR-WRK-tvPYFLPH' },
  { itemId: '315868139', productId: 'AVTR-WRK-k2yG3aQC' },
  { itemId: '315966208', productId: 'AVTR-WRK-cJChRUXh' },
  { itemId: '316091585', productId: 'AVTR-WRK-v9JCrQ4a' },
  { itemId: '316393269', productId: 'AVTR-WRK-qHk4X26z' },
  { itemId: '316744786', productId: 'AVTR-WRK-dT4LztHN' },
  { itemId: '316744788', productId: 'AVTR-WRK-PwLvWan3' },
  { itemId: '316744790', productId: 'AVTR-WRK-bhdNffHc' },
  { itemId: '316967939', productId: 'AVTR-WRK-t6RPLUYM' },
  { itemId: '316967951', productId: 'AVTR-WRK-LPd3S9fD' },
  { itemId: '316999600', productId: 'AVTR-WRK-2javyPSc' },
  { itemId: '317000001', productId: 'AVTR-WRK-imH3DGQa' },
  { itemId: '317102240', productId: 'AVTR-WRK-AYevnS69' },
  { itemId: '317436954', productId: 'AVTR-WRK-fEY7XKpy' },
  { itemId: '320119266', productId: 'AVTR-WRK-W7zu8mYA' },
  { itemId: '317989080', productId: 'AVTR-WRK-pgyHjDi8' },
  { itemId: '312450168', productId: 'AVTR-WRK-jU7GxQKR' },
  { itemId: '320020843', productId: 'AVTR-WRK-S4ebUcH5' },
  { itemId: '319502657', productId: 'AVTR-WRK-SrvZnrUk' },
  { itemId: '312284270', productId: 'AVTR-WRK-6gcsEUWW' },
  { itemId: '312285899', productId: 'AVTR-WRK-nMAPHscW' },
  { itemId: '315423281', productId: 'AVTR-WRK-C73KcTNF' },
  { itemId: '317013048', productId: 'AVTR-WRK-3twJWWBM' },
  { itemId: '315879054', productId: 'AVTR-WRK-u6rEDQuL' },
  { itemId: '321774705', productId: 'AVTR-WRK-Sx3VeSj2' },
  { itemId: '314312141', productId: 'AVTR-WRK-ZGkZ3EdA' },
  { itemId: '319615861', productId: 'AVTR-WRK-Kd25xs2q' },
  { itemId: '319503087', productId: 'AVTR-WRK-nXyQRRv7' },
  { itemId: '315868429', productId: 'AVTR-WRK-MqmxyHFF' },
  { itemId: '316758363', productId: 'AVTR-WRK-gHs7sFDb' },
  { itemId: '317436804', productId: 'AVTR-WRK-Wjd7emAG' },
  { itemId: '317445512', productId: 'AVTR-WRK-rLmgtPVW' },
  { itemId: '317450098', productId: 'AVTR-WRK-pasbtQkR' },
  { itemId: '317612685', productId: 'AVTR-WRK-ahxaiqCB' },
  { itemId: '317651326', productId: 'AVTR-WRK-zKTWRxNC' },
  { itemId: '317651351', productId: 'AVTR-WRK-SEp3dnkB' },
  { itemId: '317651467', productId: 'AVTR-WRK-tCcDduC7' },
  { itemId: '317914041', productId: 'AVTR-WRK-UNV3zXBF' },
  { itemId: '317989076', productId: 'AVTR-WRK-6hhSgGY4' },
  { itemId: '318069436', productId: 'AVTR-WRK-hEmWNcSJ' },
  { itemId: '318268534', productId: 'AVTR-WRK-rX6Nj2Hd' },
  { itemId: '318304558', productId: 'AVTR-WRK-bGXZ29Nt' },
  { itemId: '319445745', productId: 'AVTR-WRK-VtuQsxag' },
  { itemId: '319446772', productId: 'AVTR-WRK-XnJvj8gv' },
  { itemId: '319503107', productId: 'AVTR-WRK-Ri6Yw7c4' },
  { itemId: '319535353', productId: 'AVTR-WRK-npmqHvxT' },
  { itemId: '319535362', productId: 'AVTR-WRK-dszvWtT9' },
  { itemId: '319615744', productId: 'AVTR-WRK-HXs9Hnxg' },
  { itemId: '319615868', productId: 'AVTR-WRK-JLgU2Xsn' },
  { itemId: '319696043', productId: 'AVTR-WRK-PqKNVszx' },
  { itemId: '319757306', productId: 'AVTR-WRK-UwyY8H3p' },
  { itemId: '319857128', productId: 'AVTR-WRK-SKrc7Eci' },
  { itemId: '320326840', productId: 'AVTR-WRK-iMtj7Uc3' },
  { itemId: '320612278', productId: 'AVTR-WRK-4WSA8NrT' },
  { itemId: '321087975', productId: 'AVTR-WRK-jwaRXSWT' },
  { itemId: '322143403', productId: 'AVTR-WRK-pBC363Lq' },
  { itemId: '318282777', productId: 'AVTR-WRK-ReqYt8YS' },
  { itemId: '311041660', productId: 'AVTR-WRK-cLDQWXCe' },
  { itemId: '310031823', productId: 'AVTR-WRK-GfRBCsCL' },
  { itemId: '203184596', productId: 'AVTR-WRK-WzpfwdAA' },
  { itemId: '318806668', productId: 'AVTR-WRK-2mARKiiW' },
  { itemId: '315396313', productId: 'AVTR-WRK-JTFXVqYC' },
  { itemId: '304602860', productId: 'AVTR-WRK-HNs5WzJ2' },
  { itemId: '319670674', productId: 'AVTR-WRK-Mke52wpp' },
  { itemId: '319534774', productId: 'AVTR-WRK-QY44eppX' },
  { itemId: '304602856', productId: 'AVTR-WRK-cvwypLhd' },
  { itemId: '319670761', productId: 'AVTR-WRK-r5BnjHqM' },
  { itemId: '321913281', productId: 'AVTR-WRK-Tk2Qft7H' },
  { itemId: '311738471', productId: 'AVTR-WRK-MV4ZUy7R' },
  { itemId: '323302719', productId: 'AVTR-WRK-4YGuWens' },
  { itemId: '318304559', productId: 'AVTR-WRK-DUQBSmiv' },
  { itemId: '316391992', productId: 'AVTR-WRK-N7bb7Swn' },
  { itemId: '313846213', productId: 'AVTR-WRK-HPsQuKsL' },
  { itemId: '317914032', productId: 'AVTR-WRK-qg5xSe2E' },
  { itemId: '320587744', productId: 'AVTR-WRK-Rgw2aAqm' },
  { itemId: '319615951', productId: 'AVTR-WRK-ZFT39c2S' },
  { itemId: '320326920', productId: 'AVTR-WRK-PLFQNZqk' },
  { itemId: '320061862', productId: 'AVTR-WRK-d7Dn2Ljp' },
  { itemId: '314024432', productId: 'AVTR-WRK-ULGyLU2a' },
  { itemId: '319923732', productId: 'AVTR-WRK-8HugbY6L' },
  { itemId: '315845275', productId: 'AVTR-WRK-gGP2YMbH' },
  { itemId: '313489925', productId: 'AVTR-WRK-ftKDPjZN' },
  { itemId: '315423162', productId: 'AVTR-WRK-YqVWL9jz' },
  { itemId: '315879068', productId: 'AVTR-WRK-7FbjnWVK' },
  { itemId: '319923566', productId: 'AVTR-WRK-pvcATim7' },
  { itemId: '303516039', productId: 'AVTR-WRK-d4Zwv4EA' },
  { itemId: '318100023', productId: 'AVTR-WRK-qnuuvStt' },
  { itemId: '320119641', productId: 'AVTR-WRK-39P8JAj2' },
  { itemId: '319857211', productId: 'AVTR-WRK-L9qG2Qba' },
  { itemId: '320026646', productId: 'AVTR-WRK-Px9td6bT' }
];

export const IMAGE_TYPES = ['IMAGE', 'IMAGE_LEFT_VIEW', 'IMAGE_ALTERNATE_VIEW', 'IMAGE_ALTERNATE_VIEW_6'];