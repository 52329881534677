import React, { useContext, useEffect, useRef, useState } from 'react';
import { func } from 'prop-types';
import classNames from 'classnames';
import { ExperienceContext } from '@thd-nucleus/experience-context';
import { Image } from '@thd-olt-component-react/core-ui';
import './styles/toggle.scss';

export const Toggle = ({ onToggle }) => {
  const [hidden, setHidden] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);

  const { channel } = useContext(ExperienceContext);
  const isMobile = channel === 'mobile' || channel === 'tablet';
  const toggleButton = useRef(null);

  const showPeek = () => {
    if (isMobile) {
      const openInterval = 1500;
      const openAndCloseInterval = 3500;
      const { current } = toggleButton || {};
      const { classList = [] } = current || {};
      setTimeout(() => {
        classList.add('toggle-mobile--open');
      }, openInterval);
      setTimeout(() => {
        classList.remove('toggle-mobile--open');
      }, openAndCloseInterval);
    }
  };

  useEffect(() => {
    showPeek();
  }, []);

  const toggleClasses = classNames({
    toggle: !isMobile,
    'toggle-mobile': isMobile,
    'toggle-mobile--open': isMobile && drawerOpen
  });

  const iconClass = isMobile ? '' : 'toggle-icon';

  // styling in component gives desired mobile positioning vs styling in scss file
  const imageStyle = {
    width: '16px',
    left: '7px',
    top: '7px',
    position: 'absolute'
  };

  // styling in component gives desired mobile positioning vs styling in scss file
  const textStyle = {
    left: '30px',
    top: '6px',
    position: 'absolute'
  };

  const toggleHotspots = () => {
    onToggle(hidden);
    setHidden(!hidden);
  };

  const handleClickMobile = () => {
    if (drawerOpen) {
      toggleHotspots();
    }
    setDrawerOpen(!drawerOpen);
  };

  return (
    <>
      <button
        className={toggleClasses}
        onClick={isMobile ? handleClickMobile : toggleHotspots}
        ref={toggleButton}
        data-testid="toggle-button"
        type="button"
      >
        <Image
          alt="icon"
          className={iconClass}
          src="https://assets.thdstatic.com/images/v1/product-hotspot-no-shadow.svg"
          style={isMobile ? imageStyle : {}}
          width="32"
          height="32"
        />
        <span style={isMobile ? textStyle : {}}>
          {hidden ? 'Show Tags' : 'Hide Tags'}
        </span>
      </button>
    </>
  );
};
Toggle.displayName = 'Toggle';
Toggle.propTypes = {
  onToggle: func.isRequired
};
