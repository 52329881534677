/* eslint-disable react/forbid-prop-types */
import React, { Component } from 'react';
import {
  any, func, oneOfType, shape, string
} from 'prop-types';
import { Image, Loader } from '@thd-olt-component-react/core-ui';
import jsonp from 'jsonp';
import classNames from 'classnames';

const BASELINE_ROTATION_SPEED = 2000;

class Threesixty extends Component {

  constructor(props) {
    super(props);
    this.hint360Ref = React.createRef();
    this.imageRef = React.createRef();
  }

  state = {
    image: null,
    showHint: false
  };

  componentDidMount = () => {
    const { imageRef = {} } = this;
    const node = imageRef.current;
    if (node) {
      node.addEventListener('mousedown', this.handleTouchMouseDown);
      node.addEventListener('touchstart', this.handleTouchMouseDown);

      const { media } = this.props;
      this.performAutoSpin(media);
    }
  };

  componentDidUpdate(prevProps) {
    const { media } = this.props;
    if (prevProps.media.id !== media.id) {
      clearInterval(this.intervalId);
      this.intervalId = null;
      const { imageRef = {} } = this;
      const node = imageRef.current;
      if (node) {
        node.addEventListener('mousedown', this.handleTouchMouseDown);
        node.addEventListener('touchstart', this.handleTouchMouseDown);

        this.performAutoSpin(media);
      }
    }
  }

  componentWillUnmount() {
    clearInterval(this.intervalId);
    this.intervalId = null;
    const { imageRef = {} } = this;
    const node = imageRef.current;
    if (node) {
      node.removeEventListener('mousedown', this.handleTouchMouseDown);
      node.removeEventListener('mousemove', this.handleTouchMouseMove);
      node.removeEventListener('mouseup', this.handleTouchMouseUp);
      node.removeEventListener('mouseleave', this.handleTouchMouseUp);
      node.removeEventListener('touchstart', this.handleTouchMouseDown);
      node.removeEventListener('touchmove', this.handleTouchMouseMove);
      node.removeEventListener('touchend', this.handleTouchMouseUp);
    }
  }

  // eslint-disable-next-line
  changeVerticalSpinDirection = false;

  currentRow = 1;

  currentColumn = 0;

  images = [];

  isDragging = false;

  mouseX = 0;

  mouseY = 0;

  startXPosition = 0;

  startYPosition = 0;

  totalRows = 0;

  totalColumns = 0;

  intervalId = null;

  resetImage = () => {
    this.currentRow = 1;
    this.currentColumn = 1;
    this.updateImage(this.images);
  }

  getImageSrcUrls = (layers, url) => {
    if (!layers || !Object.entries(layers).length) return [];

    const imageURL = this.getImageURL(url);
    const layersArray = Object.values(layers)
      .map((layer) => Object.values(layer)
        .map((spinImage) => `${imageURL}/${spinImage}?profile=600`)
      );

    return layersArray;
  };

  getImageURL = (url) => {
    let splits = url.split('/');
    splits.pop();
    return splits.join('/');
  };

  getSpinImage = (images) => {
    if (images.length > 0 && images[0].length > 0) {
      const image = images[this.currentRow - 1][this.currentColumn - 1];
      return image || '';
    }
    return '';

  };

  getOverlaySize = () => {
    const { fwdOverlayRef, fwdImageRef } = this.props;
    let height = 0;
    let width = 0;

    if (fwdOverlayRef && fwdImageRef) {
      const oNode = fwdOverlayRef;
      const iNode = fwdImageRef.current;
      height = oNode ? oNode.clientHeight - 50 : 0;
      width = iNode ? iNode.clientWidth - 80 : 0;
    } else {
      const { imageRef = {} } = this;
      const node = imageRef.current;
      width = node ? node.clientWidth : 0;
      height = width;
    }
    return {
      height,
      width
    };
  };

  getLastTwoDigits = (digits) => {
    return (`0${Number(digits)}`).slice(-2);
  };

  shouldRotateHorizontally = (columns) => {
    return columns > 0;
  };

  shouldSpinDownwards = (columnCounter) => {
    const { currentRow, totalRows } = this;
    if (currentRow === totalRows) {
      this.changeVerticalSpinDirection = true;
    }
    return columnCounter === 0
      && currentRow <= totalRows
      && !this.changeVerticalSpinDirection;
  };

  shouldSpinUpwards = (columnCounter) => {
    const { currentRow, changeVerticalSpinDirection } = this;
    return columnCounter === 0
      && currentRow > 1
      && changeVerticalSpinDirection;
  };

  performAutoSpin = (media = {}) => {
    const { url } = media;
    if (!url) {
      return;
    }

    jsonp(url, (error, data) => {
      if (error) {
        console.error(`error while fetching 360 spin images: ${error}`);
      } else {
        this.updateData(data, url);
      }
    });
  };

  updateData = (data = {}, url) => {
    const { layers = {} } = data;
    const spin360Images = this.getImageSrcUrls(layers, url);

    if (spin360Images.length > 0) {
      const totalRows = spin360Images.length;

      this.totalColumns = spin360Images[0].length;
      this.totalRows = totalRows;
      this.images = spin360Images;

      this.rotateImages(this.images);
    }
  };

  rotateImages = (images) => {
    const { totalColumns } = this;
    let columnCounter = totalColumns;
    let index = columnCounter - 1;

    this.intervalId = setInterval(() => {
      if (this.shouldRotateHorizontally(columnCounter)) {
        this.currentColumn = columnCounter;
        this.updateImage(images);
        columnCounter--;
        index--;
      } else if (this.shouldSpinDownwards(columnCounter)) {
        this.currentRow++;
        this.updateImage(images);
      } else if (this.shouldSpinUpwards(columnCounter)) {
        this.currentRow--;
        this.updateImage(images);
      } else {
        clearInterval(this.intervalId);
        this.show360Hint();
      }
    }, this.determineSpeed());
  };

  updateImage = (images) => {
    const spinImage = this.getSpinImage(images);
    this.setState({ image: spinImage });
  };

  show360Hint = () => {
    this.setState({ showHint: true });
    setTimeout(() => {
      this.setState({ showHint: false });
    }, 3000);
  };

  determineSpeed = () => {
    const { totalColumns = 1 } = this;
    return BASELINE_ROTATION_SPEED / totalColumns;
  };

  determineSpinGap = () => {
    const { height, width } = this.getOverlaySize();
    const { totalColumns, totalRows } = this;
    const spinXGap = (width / totalColumns) < 10
      ? 10
      : width / (totalColumns * 2);
    const spinYGap = (height / totalRows) > 50
      ? 50
      : height / totalRows;

    return {
      spinXGap,
      spinYGap
    };
  };

  handleTouchMouseDown = (e) => {
    const { intervalId } = this;
    if (intervalId) {
      clearInterval(this.intervalId);
      this.intervalId = null;
    }
    this.isDragging = true;
    this.startXPosition = e.pageX || e.touches[0].pageX;
    this.startYPosition = e.pageY || e.touches[0].pageX;

    const { imageRef = {} } = this;
    const node = imageRef.current;
    if (node) {
      node.addEventListener('mousemove', this.handleTouchMouseMove);
      node.addEventListener('mouseup', this.handleTouchMouseUp);
      node.addEventListener('mouseleave', this.handleTouchMouseUp);
      node.addEventListener('touchmove', this.handleTouchMouseMove);
      node.addEventListener('touchend', this.handleTouchMouseUp);
    }
  };

  handleTouchMouseUp = () => {
    this.isDragging = false;
  };

  spinLeft = (spinXGap) => {
    const { totalColumns, images } = this;

    if (Math.abs(this.mouseX - this.startXPosition) >= spinXGap) {
      this.startXPosition = this.mouseX;
      if (totalColumns < 24 && this.currentColumn > 1) {
        this.currentColumn--;
        this.updateImage(images);
      } else {
        this.currentColumn = this.currentColumn === totalColumns
          ? 1
          : this.currentColumn + 1;
        this.updateImage(images);
      }
    }
  };

  spinRight = (spinXGap) => {
    const { totalColumns, images } = this;

    if (Math.abs(this.mouseX - this.startXPosition) >= spinXGap) {
      this.startXPosition = this.mouseX;
      if (totalColumns < 24 && this.currentColumn < totalColumns) {
        this.currentColumn++;
        this.updateImage(images);
      } else {
        this.currentColumn = this.currentColumn === 1
          ? totalColumns
          : this.currentColumn - 1;
        this.updateImage(images);
      }
    }
  };

  spinDown = (spinYGap) => {
    const { images } = this;

    if (Math.abs(this.mouseY - this.startYPosition) > spinYGap) {
      this.startYPosition = this.mouseY;
      if (this.currentRow > 1) {
        this.currentRow--;
        this.updateImage(images);
      }
    }
  };

  spinUp = (spinYGap) => {
    const { images, totalRows } = this;

    if (Math.abs(this.mouseY - this.startYPosition) > spinYGap) {
      this.startYPosition = this.mouseY;
      if (this.currentRow < totalRows) {
        this.currentRow++;
        this.updateImage(images);
      }
    }
  };

  handleTouchMouseMove= (e) => {

    if (this.isDragging) {

      this.mouseX = e.pageX || e.touches[0].pageX;
      this.mouseY = e.pageY || e.touches[0].pageY;

      const { spinXGap, spinYGap } = this.determineSpinGap();

      if (this.mouseX < this.startXPosition) {
        this.spinLeft(spinXGap);
      } else if (this.mouseX > this.startXPosition) {
        this.spinRight(spinXGap);
      }

      if (this.mouseY < this.startYPosition) {
        this.spinDown(spinYGap);
      } else if (this.mouseY > this.startYPosition) {
        this.spinUp(spinYGap);
      }
    }
  };

  render() {
    const { className } = this.props;
    const { showHint, image } = this.state;
    const { totalRows } = this;
    const hasMultipleRows = totalRows > 1;

    const hint360Classes = classNames(
      'sui-absolute sui-w-full sui-h-full sui-flex sui-inset-y-0 sui-left-0 sui-z-10 sui-my-0'
      + ' sui-scale-50 sui-ease-linear sui-duration-300 sui-transition-all sui-bg-center sui-bg-no-repeat'
      , {
        'sui-opacity-100': showHint,
        'sui-opacity-0': !showHint
      });
    const imageStyles = classNames('stretchy', className);

    return (
      <div className="sui-relative sui-flex sui-justify-center" data-testid="main360">
        <button ref={this.imageRef} className="sui-aspect-square sui-max-w-xl sui-max-h-xl" type="button">
          {image
              && <Image className={imageStyles} src={image} alt="360" lazy />}
          {!image
              && <Loader active />}
          <span
            ref={this.hint360Ref}
            className={hint360Classes}
            // eslint-disable-next-line max-len
            style={{ backgroundImage: `url("https://assets.thdstatic.com/images/v1/360_${(hasMultipleRows ? 'multiple_row' : 'right-left')}.png")` }}
          />
        </button>
      </div>
    );
  }
}

Threesixty.propTypes = {
  className: string,
  media: shape({
    id: string,
    url: string,
    mediaStill: string,
    type: string
  }).isRequired,
  fwdOverlayRef: oneOfType([
    func,
    shape({ current: any })
  ]),
  fwdImageRef: oneOfType([
    func,
    shape({ current: any })
  ])
};

Threesixty.defaultProps = {
  className: null,
  fwdOverlayRef: null,
  fwdImageRef: null
};

export default Threesixty;
